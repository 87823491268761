// import PropTypes from "prop-types"
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SlideShow from "../components/slider"
import Blurb from "../components/blurb"
import Header from "../components/header"
import Seo from "../components/seo"
import { Row, Container } from "react-bootstrap"
import BodyCard from "../components/bodyCards"

export const query = graphql`
  {
    protectionLogo: file(relativePath: { eq: "protection-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 2140) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rustPanel: file(relativePath: { eq: "rust-panel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    snowPlow: file(relativePath: { eq: "snowplow.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    babies: file(relativePath: { eq: "babies.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coffee: file(relativePath: { eq: "fries.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leaves: file(relativePath: { eq: "leaves.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    protection: file(relativePath: { eq: "road-protection.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    protect: file(relativePath: { eq: "protect.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    undercoatProtection: file(relativePath: { eq: "undercoat-spray.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    flatTire: file(relativePath: { eq: "flat-tire.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wetTire: file(relativePath: { eq: "wet-tire.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const TiresRims = ({ data }) => {
  const phoneNum = process.env.GATSBY_PHONE_NUMBER
  const leaves = data.leaves.childImageSharp.fluid
  const protectionLogo = data.protectionLogo.childImageSharp.fluid
  const babies = data.babies.childImageSharp.fluid
  const snowPlow = data.snowPlow.childImageSharp.fluid
  const wetTire = data.wetTire.childImageSharp.fluid
  const content = [
    {
      title: "Rust Never Sleeps",
      description: "Protect your vehicle against the formation of rust",
      image: `${snowPlow.src}`,
      button: "Call Now",
      phone: `${phoneNum}`,
    },
    {
      title: "Undercoat Protection",
      description: "Create a quiet ride while protecting your vehicle",
      image: `${wetTire.src}`,
      button: "Call Now",
      phone: `${phoneNum}`,
    },
    {
      title: "Interior Protection",
      description: "Condition and protect your vehicle's interior",
      image: `${babies.src}`,
      button: "Call Now",
      phone: `${phoneNum}`,
    },
    {
      title: "Paint Protection",
      description: "Clearcoat finish protects your vehicle's paint",
      image: `${leaves.src}`,
      button: "Call Now",
      phone: `${phoneNum}`,
    },
  ]

  const text =
    "Your vehicle rolled into your life with the latest innovations in design and construction. It still has to battle the wear and tear of our harsh Canadian climate. Strong UV rays, extreme heat or cold, snow and ice, rain or humidity, road salt and pollutants can harm your car's beauty and it's functions. Exterior and interior safeguards from Secure Ride Protection will defend your vehicle against harsh conditions."
  const cardContent = {
    1: {
      img: data.rustPanel.childImageSharp.fluid,
      alt: "Rusty car panel",
      list: [
        "Synthetic waterproof product",
        "Sprayed into inner cavities and crevices",
        "One-time application for life of your vehicle",
        "Environmental Surface Rust Guarantee against rusting",
      ],
      header: "Rust Inhibitor Spray:",
    },
    2: {
      img: data.undercoatProtection.childImageSharp.fluid,
      alt: "Spraying Undercoat",
      list: [
        "Preserve vehicle's most vulnerable, high-impact areas",
        "Seals against moisture and road salt",
        "Won't crack or split",
        "Lessen road noise for quieter ride",
      ],
      header: "Undercoat Protection:",
    },
    3: {
      img: data.protect.childImageSharp.fluid,
      alt: "Hand Applying Paint Protection",
      list: [
        "Long lasting sealant",
        "Protects paint against fading",
        "Durable finish leaves surface smooth",
        "Environmental Upgrade guarantees against acid rain, tree sap and bird droppings",
      ],
      header: "Paint Protection:",
    },
    4: {
      img: data.coffee.childImageSharp.fluid,
      alt: "Babies Sleeping in car seats",
      list: [
        "Guarantees against seat stains due to spills",
        "Guarantees against leather/vinyl cracking or hardening",
        "Environmental Interior guarantees any interior surface",
        "Rip, Tear, or Burn guarantees against accidental rips, tears or burns",
      ],
      header: "Interior Protection:",
    },
  }

  return (
    <Layout
      className="index-container mt-1"
      pageInfo={{ pageName: "tire-rim" }}
    >
      <Seo
        title="Autoguard"
        keywords={[`tires`, `damage`, `protection`, `towing`, `replacement`]}
      />
      <SlideShow data={content} />
      <Header logo={protectionLogo.src} />
      <Blurb text={text} />
      <Container fluid>
        <Row>
          <BodyCard content={cardContent} />
        </Row>
      </Container>
    </Layout>
  )
}

export default TiresRims
